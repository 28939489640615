import React, { useEffect, useRef } from "react";
import io from "socket.io-client";

const pc_config = {
  iceServers: [
    // {
    //   urls: 'stun:[STUN_IP]:[PORT]',
    //   'credentials': '[YOR CREDENTIALS]',
    //   'username': '[USERNAME]'
    // },
    {
      urls: "stun:stun.l.google.com:19302",
    },
  ],
  iceCandidatePoolSize: 10,
};
const SOCKET_SERVER_URL = "https://p2p-server.devarmiwa.com";

const App = () => {
  const socketRef = useRef<SocketIOClient.Socket>();
  const pcRef = useRef<RTCPeerConnection>();
  const localVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  const _removeCodec = (orgsdp: string, codec: string): string => {
    const internalFunc: any = (sdp: string) => {
      const isVideo = (codec == 'H264' || codec == 'VP8' || codec == 'VP9') ? true : false;
      const codecre = new RegExp('(a=rtpmap:(\\d*) ' + codec + '.*\r\n)');
      const rtpmaps = sdp.match(codecre);
      if (rtpmaps == null || rtpmaps.length <= 2) {
        return sdp;
      }
      const rtpmap = rtpmaps[2];
      let modsdp = sdp.replace(codecre, "");

      const rtcpre = new RegExp('(a=rtcp-fb:' + rtpmap + '.*\r\n)', 'g');
      modsdp = modsdp.replace(rtcpre, "");

      const fmtpre = new RegExp('(a=fmtp:' + rtpmap + '.*\r\n)', 'g');
      modsdp = modsdp.replace(fmtpre, "");

      const aptpre = new RegExp('(a=fmtp:(\\d*) apt=' + rtpmap + '\\r\\n)');
      const aptmaps = modsdp.match(aptpre);
      let fmtpmap = "";
      if (aptmaps != null && aptmaps.length >= 3) {
        fmtpmap = aptmaps[2];
        modsdp = modsdp.replace(aptpre, "");

        const rtppre = new RegExp('(a=rtpmap:' + fmtpmap + '.*\r\n)', 'g');
        modsdp = modsdp.replace(rtppre, "");
      }

      let avre;
      if (isVideo) avre = /(m=video.*\r\n)/;
      else avre = /(m=audio.*\r\n)/;
      const avlines = modsdp.match(avre);
      if (avlines != null) {
        const avline = avlines[0].substring(0, avlines[0].length - 2);
        const avelems = avline.split(" ");
        // m=audio의 port가 G722 codec의 PT가 겹처서 port(avelems[1])를 제외
        let modavline = avelems[0] + ' ' + avelems[1];
        avelems.forEach((avelem, index) => {
          if (index === 0 || index === 1) return;
          if (avelem == rtpmap || avelem == fmtpmap) {
            return;
          }
          modavline += " " + avelem;
        })
        modavline += "\r\n";
        modsdp = modsdp.replace(avre, modavline);
      }
      return internalFunc(modsdp);
    }
    return internalFunc(orgsdp);
  }

  const setVideoTracks = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      if (localVideoRef.current) localVideoRef.current.srcObject = stream;
      if (!(pcRef.current && socketRef.current)) return;
      stream.getTracks().forEach((track) => {
        if (!pcRef.current) return;
        pcRef.current.addTrack(track, stream);
      });
      pcRef.current.onicecandidate = (e) => {
        if (e.candidate) {
          if (!socketRef.current) return;
          console.log("onicecandidate");
          if (e.candidate.type === 'srflx')
            socketRef.current.emit("candidate", e.candidate);
        }
      };
      pcRef.current.oniceconnectionstatechange = (e) => {
        console.log(e);
      };
      pcRef.current.onconnectionstatechange = (e) => {
        console.log(e);
        if (pcRef.current && pcRef.current.connectionState === 'failed') {
          pcRef.current?.restartIce();
        }
      };
      pcRef.current.ontrack = (ev) => {
        console.log("add remotetrack success");
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = ev.streams[0];
        }
      };
      socketRef.current.emit("join_room", {
        room: "1234",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createOffer = async () => {
    console.log("create offer");
    if (!(pcRef.current && socketRef.current)) return;
    try {
      const sdp = await pcRef.current.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      });
      sdp.sdp = sdp.sdp?.replace(/a=recvonly\r\n/g, "a=sendrecv\r\n");
      var removeCodecs = [
        // videos
        // 'H264',
        'VP8', 'VP9',
        // audios
        // 'rtx',
        'red', 'ulpfec', 'ISAC',
        'G722',
        'PCMU', 'PCMA', 'CN', 'telephone-event',
      ];
      removeCodecs.forEach(function (codec: string) {
        sdp.sdp = _removeCodec(sdp.sdp ?? '', codec);
      });
      await pcRef.current.setLocalDescription(new RTCSessionDescription(sdp));
      socketRef.current.emit("offer", sdp);
    } catch (e) {
      console.error(e);
    }
  };

  const createAnswer = async (sdp: RTCSessionDescription) => {
    if (!(pcRef.current && socketRef.current)) return;
    try {
      await pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
      console.log("answer set remote description success");
      const mySdp = await pcRef.current.createAnswer({
        offerToReceiveVideo: false,
        offerToReceiveAudio: true,
      });
      mySdp.sdp = mySdp.sdp?.replace(/a=recvonly\r\n/g, "a=sendrecv\r\n");
      console.log("create answer");
      await pcRef.current.setLocalDescription(new RTCSessionDescription(mySdp));
      socketRef.current.emit("answer", mySdp);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    socketRef.current = io.connect(SOCKET_SERVER_URL);
    pcRef.current = new RTCPeerConnection(pc_config);

    socketRef.current.on("all_users", (allUsers: Array<{ id: string }>) => {
      if (allUsers.length > 0) {
        createOffer();
      }
    });

    socketRef.current.on("getOffer", (sdp: RTCSessionDescription) => {
      //console.log(sdp);
      console.log("get offer");
      createAnswer(sdp);
    });

    socketRef.current.on("getAnswer", (sdp: RTCSessionDescription) => {
      console.log("get answer");
      if (!pcRef.current) return;
      pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
      //console.log(sdp);
    });

    socketRef.current.on(
      "getCandidate",
      async (candidate: RTCIceCandidateInit) => {
        if (!pcRef.current) return;
        await pcRef.current.addIceCandidate(new RTCIceCandidate(candidate));
        console.log("candidate add success");
      }
    );

    setVideoTracks();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (pcRef.current) {
        pcRef.current.close();
      }
    };
  }, []);

  return (
    <div>
      <video
        style={{
          width: 240,
          height: 240,
          margin: 5,
          backgroundColor: "black",
        }}
        muted
        ref={localVideoRef}
        playsInline
        autoPlay
      />
      <video
        id="remotevideo"
        style={{
          width: 240,
          height: 240,
          margin: 5,
          backgroundColor: "black",
        }}
        ref={remoteVideoRef}
        playsInline
        autoPlay
      />
    </div>
  );
};

export default App;

